import React, { createContext, useState } from 'react'
import { ISelectOrgContextType, ISelectOrgProviderProps, IOrganizationChild } from './types';

// Create the context with the default value of `undefined`
const selectOrgAgency = createContext<ISelectOrgContextType | undefined>(undefined);

export function SelectOrgProvider({ children }: ISelectOrgProviderProps) {
    const [agencyList, setAgencyList] = useState<IOrganizationChild[]>([])
    const [selectedAgency, setSelectedAgency] = useState<number | undefined>();
    const [isAgencyModalVisible, setIsAgencyModalVisible] = useState<boolean>(false);
    return (
        <selectOrgAgency.Provider
            value={{
                orgAgencyList: [agencyList, setAgencyList],
                selectedAgencyId: [selectedAgency, setSelectedAgency],
                agencyModal: [isAgencyModalVisible, setIsAgencyModalVisible]

            }}
        >
            {children}
        </selectOrgAgency.Provider>
    )
}

export const useSelectOrg = () => {
    const context = React.useContext(selectOrgAgency)
    if (context === undefined) {
        throw new Error('selectOrgAgency must be used within a selectOrgAgency')
    }
    return context
}